.dropdown {
    position: relative;
    display: inline-block;
    margin-bottom: 1rem;
  }
  
  .dropdown select {
    background-color: #501e92 !important; /* Fondo transparente */
    color: rgb(255, 255, 255); /* Color violeta */
    font-size: inherit;
    margin: 0;
    width: 18vw;
    height: 30px;
    text-indent: 5px;
    padding-left: 2vw;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }


  /* Estilo de la lista */
  .dropdown ul {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    border: none; 
    margin: 0;
    padding: 0;
  }
  
  /* Estilo de los elementos */
  .dropdown li {
    padding: 10px;
  }